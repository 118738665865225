import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby';

// markup
const NotFoundPage = () => {
  return (
    <Layout theme='light'>
      <div className={'flex flex-col justify-center items-center h-hero bg-white text-black'}>
        <div className={'items-left w-80'}>
          <h1 style={{fontFamily: 'Geomanist Medium'}} className={'text-7xl mb-2'}>404. <span role='img' aria-label='hand wave emoji' className={'text-6xl'}>🧐</span></h1>
          <p className={'mb-6'}>I can't find that page anywhere. Take a look at my <Link className='border-b-2 border-dotted border-black hover:text-blue hover:border-blue hover:border-solid' to='/#work'>work</Link> to find something interesting.</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
